.main {
  background-image: url('../../images/hero-background.png');
  background-position: center;
  min-height: 640px;
  width: 100%;
  margin-top: -48px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main__content {
  max-width: 768px;
  margin: 120px;
}

.main__title {
  font-weight: 600;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 12px;
}
.main__subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.1;
}

@media screen and (max-width: 880px) {
  .main {
    min-height: 480px;
  }
  .main__content {
    margin: 60px;
  }
  .main__title{
    font-size: 28px;
  }
  .main__subtitle{
    font-size: 20px;
  }
}
@media screen and (max-width: 640px) {
  .main {
    min-height: 320px;
    background-position: left;
  }
}
@media screen and (max-width: 540px) {
  .main {
    margin-top: -32px;
  }

  .main__content{
    margin: 24px
  }
}
