.header {
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin-top: 24px;
  padding: 0 40px;
}

.header__logo {
  max-width: 300px;
  width: 100%;
  padding-right: 24px;
}

.header__items {
  display: flex;
  justify-content: space-around;
  max-width: 512px;
  list-style: none;
}

.header__item {
  font-family: Quicksand, Georgia, 'Times New Roman', Times, serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-left: 20px;
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 880px) {
  .header__nav {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .header {
    padding: 0 16px;
  }
  .header__logo {
    max-width: 240px;
  }
}
