@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  h2 {
    @apply font-sans font-semibold text-2xl mb-5 text-main;
  }
}