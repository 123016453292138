.about{
  max-width: 880px;
  margin: 48px auto;
  padding: 80px 16px 0;
}

.about__content{
 display: flex;
 justify-content: space-between;
}

.about__text{
  max-width: 512px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.about__title{
  font-family: Quicksand, Georgia, 'Times New Roman', Times, serif;
  font-weight: 700;
}

.about__paragraph{
  color:#004E7C;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 12px;
}

.about__button{
  background-color: #004E7C;
  color: white;
  max-width: 180px;
  min-height: 48px;
  width: 100%;
  border: none;
  border-radius: 10px;
  margin: 24px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__image{
  max-height: 384px;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  object-fit: cover;
  object-position: top;
}

@media screen and (max-width: 640px) {
  .about__content{
    flex-direction: column;
    align-items: center;
  }

  .about__title{
    margin-top: 24px;
  }

  .about__paragraph{
    font-size: 16px;
    line-height: 1.3;
  }

  .about__text{
    order: 1;
  }
  .about__image{
    height: 240px;
    width: 240px;
    border-radius: 50%;
    order: 0;
  }
  
}