.contact {
  max-width: 880px;
  margin: 96px auto 24px;
  background-color: #dce1e3;
  min-height: min-content;
  padding: 48px 16px;
}

.contact__content {
  margin: 0 auto;
  max-width: 768px;
  padding: 40px;
  border-radius: 12px;
  background-color: white;
}

.contact__title{
  font-family: Quicksand, Georgia, 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
  font-size: 28px;
}

.contact__text{
  text-align: center;
}

.contact__icons{
  list-style: none;
  display: flex;
  justify-content: space-around;
  max-width: 480px;
  margin: 32px auto;
  padding: 0
}

.contact__element{
  max-width: 64px;
}

.contact__icon{
  width: 100%;
}

@media screen and (max-width: 500px){
  .contact__icon{
    max-width: 48px;
  }

  .contact__content{
    padding: 16px;
  }
}