.project-card {
  border-radius: 10px;
  padding: 20px;
  max-width: 320px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 0 auto;
}

.project__image {
  max-height: 240px;
  width: auto;
  border-radius: 12px;
  object-fit: contain;
  margin: 0 auto;
  transition: transform linear 0.25s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
}

.project__image:hover {
  transform: scale(1.02);
}

.project__title {
  font-family: Quicksand, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 16px 0 8px;
  text-align: center;
}

.project__desc {
  margin: 24px auto;
  font-size: 16px;
}

.project__tech {
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: center;
  max-width: 66%;
  margin: 0 auto;
}

.project__icon {
  max-width: 32px;
  margin-right: 4px;
  object-fit: contain;
}

.project__links {
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
  font-family: Quicksand, Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-decoration: underline;
  list-style: none;
  padding: 0;
}

.project__link {
  text-decoration: none;
  color: inherit;
}
