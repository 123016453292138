.top-button {
  position: fixed;
  right: 48px;
  bottom: 60px;
  max-width: 36px;
}

.top-button__image {
  width: 100%;
}
