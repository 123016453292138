@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./source-sans-pro-v19-latin-regular.woff2') format('woff2'),
    url('./source-sans-pro-v19-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('./source-sans-pro-v19-latin-600.woff2') format('woff2'),
    url('./source-sans-pro-v19-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./source-sans-pro-v19-latin-700.woff2') format('woff2'),
    url('./source-sans-pro-v19-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./quicksand-v28-latin-500.woff2') format('woff2'),
    url('./quicksand-v28-latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./quicksand-v28-latin-700.woff2') format('woff2'),
    url('./quicksand-v28-latin-700.woff') format('woff');
}
